import React from 'react'
import { graphql } from 'gatsby'
import ProductTemplate from 'components/templates/TourTemplate/TourTemplatePrivate'

const PompeiiPage = () => {
  const products = {
    0: {
      productId: 'PMPPRVTTOUR',
      productName: 'bookingTemplate.pompeiPrivate.title',
      productImages: {
        0: 'tour/pompei/pompei.jpg',
      },
      productLink: 'http://pompeiify.rezgo.com/details/296812/pompeii-private-tour',
      productMaps: 'https://www.google.com/maps/d/embed?mid=11TB5rcIBHoplRuRYpTZ9xIj7GyatGR0x&hl=it',
    },
  }

  const content = {
    overview: 'bookingTemplate.pompei.overview',
    itineraryContent: 'bookingTemplate.pompei.itineraryContent',
    firstTime: 'bookingTemplate.pompeiPrivate.firstTime',
    duration: 'bookingTemplate.pompei.duration',
    tPrice: 'bookingTemplate.pompeiPrivate.tPrice',
    ePrice: 'bookingTemplate.pompei.ePrice',
    rTicket: 'bookingTemplate.pompei.rTicket',
    fTicket: 'bookingTemplate.pompei.fTicket',
    queue: 'bookingTemplate.pompei.queue',
    hint: 'bookingTemplate.pompei.hint',
    place: 'bookingTemplate.pompei.place',
    faqLink: '/pompeii-faq',
  }

  return (
    <>
      <ProductTemplate products={products} content={content} />
    </>
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["translation"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default PompeiiPage
